.side-container {
  position: fixed;
  right: 0;
  left: 0;
  display: flex;
  flex-wrap: nowrap;
  bottom: 0;
  width: 100vw;
}

.side-box {
  background: #312d30;
  padding: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 1000;
  flex-grow: 1;
  height: 3rem;
  transition: right 0.55s, background 0.5s, border 0.5s;
  margin-right: 0.3rem;
}

.side-box:last-child {
  margin-right: 0;
}

.side-box:hover,
.side-box-highlight {
  background: var(--color-primary);
}

.side-box img {
  height: 2rem;
  width: 2rem;
}

.side-box a {
  color: white;
}

.side-box a:hover {
  color: white;
}

.side-box span {
  display: none;
}

@media screen and (min-width: 48rem) {
  .side-box img {
    height: 1.5rem;
    width: 1.5rem;
  }

  .side-container {
    right: -9.5rem;
    left: inherit;
    bottom: auto;
    top: 10rem;
    flex-wrap: wrap;
    z-index: 10000;
    width: 12rem;
    flex-direction: column;
  }

  .side-box {
    right: 0;
    justify-content: flex-start;
    align-content: flex-start;
    width: 12rem;
    height: auto;
    margin-bottom: 0.3rem;
  }

  .side-box span {
    margin-left: 1rem;
    color: white;
    display: block;
  }

  /*.side-mail {*/
  /*top: 10rem*/
  /*}*/
  /*.side-kurs {*/
  /*top: 12.6rem*/
  /*}*/
  /*.side-probe {*/
  /*top: 15.25rem*/
  /*}*/
  /*.side-mitglied {*/
  /*top: 17.85rem*/
  /*}*/
  .side-box:hover {
    right: 9.5rem;
  }
}