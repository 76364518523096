.listing {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.filter-box {
  background: white;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .55);
  -moz-box-shadow: 0 0 2px 0 rgba(0, 0, 0, .55);
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, .55);

  margin-bottom: 1rem;

}
.filter-box--search {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
}
.filter--category-search,
.filter--free-search {
  width: 100%;
}
.filter--free-search input,
.filter--category-search select {
  width: 100%;
  text-align: left;
}
.filter--free-search input  {
  margin-top: 1rem;
}

.filter--free-search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}


.filter-box--head {
  padding: 1rem;
  margin-bottom: 0;
}
.filter-box--trenner {
  border-bottom: solid 1px #c9c9c9;
  width: 100%;
}
.filter-box--body {
  padding: 1rem;
}
.category--list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
.category--item {
  font-size: 1.2rem;
  padding: 0.5rem 0;
}
.category--item:hover,
.category--item.active {
  cursor: pointer;
  color: var(--color-primary);
}

.products--wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}
.product {
  margin-bottom:1rem;
  margin-right: 0;
  padding: 1rem;
  width:  100%;
  background: white;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .55);
  -moz-box-shadow: 0 0 2px 0 rgba(0, 0, 0, .55);
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, .55);
  text-align: center;
}

.product-img {
  margin-bottom: 1rem;
}
.product-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-end;
  flex-wrap: wrap;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 0;
  background: color(black a(80%));
  transition: height 0.5s linear;
}
.product:hover .product-overlay{
  cursor: pointer;
  height: 100%;
}
.product-overlay .btn {
  width: 90%;
  margin-bottom: 1rem;
  padding: 0.6rem 0.5rem;
}
.product-overlay .btn:last-of-type {
  margin-bottom: 2rem;
}
.product-title {
  font-family: var(--font-normal);
  margin-bottom: 0.2rem;
  color: var(--color-primary);
  font-size: 1.3rem;
}
.product-price {
  font-size: 1.1rem;
}
.detail--list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
.detail {
  margin-bottom: 1rem;
  border-bottom: solid 1px #c9c9c9;
  text-align: left;
}
.detail-titel {
  width: 100%;
  display: inline-flex;
  text-align: left;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}
.detail-input {
  display: inline-flex;
  width: 100%;
  margin-bottom: 0.5rem;
  text-align: left;
  justify-content: flex-end;
}
.detail-control label{
  width: 25%;
  margin-right: 0.5rem;
}
.detail-control input{
  width: 50px;
  margin-right: 0.5rem;
}
.detail-control button {
  width: 45%;
  padding: 0.5rem;
}
.detail--item-unit {
  margin-left: 0.5rem;
}
.detail-control {
  text-align: right;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}
.details--anfragen {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
}
input#search {
  height: 2.5rem;
}
select.minimal {
  -moz-appearance: none;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;

  transition: linear-gradient 5s ease-in;
}

select.minimal:focus {
  background-image:
    linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}

img.no-image.pure-img {
  width: 60%;
  margin: auto;
}
@media screen and (min-width: 35.5rem) {
  .product {
    margin-bottom:2%;
    margin-right: 2%;
    padding: 1rem;
    width: calc(98% / 2);
  }
  .product:nth-child(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 48rem) {

  .filter--category-search,
  .filter--free-search {
    width: 50%;
  }
  .filter--free-search input,
  .filter--category-search select {
    width: 300px;
    text-align: center;
  }
  .filter--free-search input {
    text-align: center;
    margin-top: 0;
  }
  .products {
    padding: 0 0 1rem 1rem;
  }

}
@media screen and (min-width: 64rem) {
  .filter-box {
    margin-right: 1rem;
  }
  .products {
    padding: 0rem 1rem 1rem 1rem;
  }
  .product {
    margin-bottom:0.8rem;
    margin-right: calc(3% / 2);
    padding: 1rem;
    width: calc(97% / 3);
  }
  .product:nth-child(2n) {
    margin-right: calc(3% / 2);
  }
  .product:nth-child(3n) {
    margin-right: 0;
  }
}