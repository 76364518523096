.anfrage .filter-box {
  margin: 0 0 1rem;
}
.anfrage .detail {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.anfrage .detail-titel {
  width: 100%;
  display: block;
}

.anfrage .detail-input {
   width: 100%;
   display: flex;
   justify-content: flex-end;
   align-content: center;
   align-items: center;
   flex-wrap: wrap;
   flex-direction: row;
 }
.anfrage .detail-control {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}


.anfrage .detail-input > span {
  width: 50%;
  text-align: right;
}
@media screen and (min-width: 48rem) {
  .anfrage .detail-titel {
    width: 30%;
  }
  .anfrage .detail-input {
    width: 40%;
  }
  .anfrage .detail-control {
    width: 30%;
  }
}
@media screen and (min-width: 64rem) {
  .anfrage .detail-titel {
    width: 40%;
  }
  .anfrage .detail-input {
    width: 30%;
  }
  .anfrage .detail-control {
    width: 30%;
  }
}