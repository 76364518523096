.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--color-primary);
}

.container-slider {
  width: 100%;
}

.slider-icon {
  min-height: 10rem;
}

.slider-icon img {
  height: 100%;
  display: block;
  position: absolute;
  right: 0;
}

.content-slider-wrapper {
  display: block;
  align-items: center;
  position: relative;
}

.slider-content {
  width: 100%;
  display: block;

}

.slider-content.container {
  padding: 0;
  margin: 0;
}

.slider-content-inner {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  display: inline-block;
}

.slider-background {
  overflow: hidden;
  display: block;
  position: relative;
  margin-right: auto;
  color: white;
  width: 100%;
}

.slider-background-text {

  width: auto;


}

.slide-titel {
  font-size: 30px;
  color: white;
  font-weight: 100;
  margin-bottom: 0.5rem;
  letter-spacing: 2px;
}
.slider-background-text h1 {
  background: var(--color-primary);
  padding: 1rem;
}
.slider-content p {
  font-family: var(--font-normal);
  color: white;
  margin-bottom: 0;
  font-size: 20px;
  letter-spacing: 1px;
  background: var(--color-primary);
  padding: 1rem;
}

.slide--button-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.slider-content .btn {
  display: inline-block;
  position: relative;
  margin: 1rem auto;
  background: var(--color-secondary);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.55);
}

.slider-content .btn:hover {
  border: solid 5px #4f5356;
  color: white;
}

.slider-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}

.swiper-pagination {
  display: none;
}

/*@media screen\0 {
  .content-slider-wrapper {
    position: relative;
  }

  .slide--button-container {
    display: block !important;
    width: 100%;
    left: 0;
  }

  .slide--button-container a {
    margin-top: 0 !important;
    text-align: center;
    display: inline-block !important;
    position: relative !important;
  }
}*/
@media screen and (min-width: 48rem) {
  .slider-icon img {
    width: 100%;
    display: block;
    position: absolute;
    right: 0;
  }
}

@media screen and (min-width: 64rem) {
  .slider-background {
    width: auto;
  }

  .slider-content {
    z-index: 5;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
    bottom: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: auto;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
  }

  .slider-icon,
  .slider-icon img {
    width: 100%;
    position: relative;
    height: auto;
    min-height: inherit;
    left: inherit;
  }

  .slider-icon img {
    max-width: 100%;
    max-height: none;
    height: auto;
  }

  .slider-icon,
  .slider-icon img {
    position: relative;
    height: auto;
    min-height: inherit;
    left: inherit;
  }

  .slider-icon {
    width: 100%;
  }

  .slider-icon img {
    max-width: none;
    height: auto;
    width: 100%;
  }

  .slide-titel {
    font-size: 42px;

  }

  .slider-background-text {
    margin-top: 1rem;
  }

  .slider-content p {
    font-size: 29px;
  }

  .slider-content .btn:hover {
    padding: 0.5rem 1rem;
    border: none;
  }

  .slide-titel {
    margin-bottom: 0.5rem;
  }

  .slider-content p {
    line-height: 2rem;
  }

  .slide--button-container {
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }

  .slider-content .btn {
    margin-left: 0;
  }

  .swiper-pagination {
    display: block;
  }

  .slider-content.container {
    width: 60rem;
    margin: 0 auto;
  }

  .slide-titel {
    font-size: 49px;
    margin-bottom: 0.5rem;
  }

  .slider-content p {
    font-size: 29px;
  }

  .slider-content .btn {
    height: auto;
    width: auto;
    padding: 0.5rem 1rem;
    border: none;

  }
}

@media screen and (min-width: 80rem) {
  .slider-icon img {
    max-height: none;
  }

  .slider-content.container {
    width: 75rem;
  }
}
