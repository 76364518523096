.cta {
  margin-top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.cta--inner {
  background: rgba(0, 0, 0, 0.6);
}

.cta--inner > .container {
  padding-top: 7rem;
  padding-bottom: 4rem;
  text-align: center;
  color: white;
}

.cta-content {
  font-family: var(--font-header);
  font-size: 2.5rem;
  line-height: 3.3rem;
  margin-bottom: 4rem;
}
.cta--info {
  position: absolute;
  top: -1.16rem;
  left: 0;
  right: 0;
}
.cta--info-img {
  width: 8rem;
  height: auto;
  margin: auto;
  display: block;
}
@media screen and (min-width: 48rem) {
  .cta--inner > .container {
    padding: 8rem 3rem 3rem;
  }
  .cta--info {
    left: 15%;
    right: auto;
  }
}

@media screen and (min-width: 80rem) {
  .cta--inner > .container {
    padding: 6rem 3rem 3rem;
  }
}