/*
 * Filename: theme.css
 *
 * Stylesheet for all theme relevant attributes like colors, fonts ...
 */

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');

:root {

  /** Schriftgrößen */
  --font-size: 1rem;
  --font-size-title: 2.625rem;
  --font-size-heading: 1.625rem;
  --font-size-subheading: 1.25rem;
  --font-size-small: .8125rem;

  /** Raster */
  --line-height: 1.5em;

  --gutter: 1rem;
  --gutter-horizontal: 1rem;

  /** Farben */
  --font-color: #312d30;
  --code: #c7254e;
  --error: #c7254e;
  --success: #38ae67;
  --color-grey-light: #ccc;
  --color-grey-dark: #333;
  --color-primary: #682820;
  --color-secondary: #ffc600;

  /** Fonts **/

  --font-header: 'Open Sans', sans-serif;
  --font-normal: 'Open Sans', sans-serif;
  --font-light: 'Open Sans', sans-serif;
  --font-bold: 'Open Sans', sans-serif;
}


@custom-media --viewport-sm (min-width: 35.5rem);
@custom-media --viewport-md (min-width: 48rem);
@custom-media --viewport-lg (min-width: 64rem);
@custom-media --viewport-xl (min-width: 80rem);
