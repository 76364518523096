.kacheln {
  padding-bottom: 1rem;
  margin-top: var(--gutter);
  margin-bottom: var(--gutter);

}

.boxen {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  align-items: stretch;

}

.box {
  width: 100%;
  text-align: center;
}

.box-leistung--content {
  margin-top: 1rem;
}

.box-leistung--link {
  font-family: var(--font-normal);
  font-weight: 600;
}

.box-leistung--img {
  width: 150px;
  height: 150px;
  border-radius: 150px;
  border: 2px solid #e6e6e6;
  padding: 2.5rem;
  margin: 2rem auto;
  background: white;
}

.box-leistung--img img {
  height: auto;
  width: 100%;
}


@media screen and (min-width: 35.5rem) {
  .boxen-background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 50%;
    width: 100%;
    background-image: url(img/welle.svg);
    background-size: cover;
    background-position: 0;
  }

  .box {
    width: calc(90% / 3);
  }
}

@media screen and (min-width: 48rem) {
  .box {
    width: calc(80% / 3);
  }
}

