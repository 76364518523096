.news {
  margin-bottom: 0;
  margin-top: calc(var(--gutter) * 2);
  background-size: auto;
  background: url(img/punkte-hintergrund-hell.png) repeat left top;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

section.news.news--content {
  background: none;
}
.news .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 1rem;
}

.news-wrapper,
.event-wrapper {
  width: 100%;
}

.news--boxen,
.event--boxen {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
}
section.news.news--content .news--boxen,
section.news.news--content .event--boxen {
  justify-content: flex-start;
}
.news-wrapper > h2,
.event-wrapper > h2 {
  text-align: center;
}

.news--box,
.event--box {
  width: 100%;
  margin-bottom: 1rem;
}

.news--box {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  /* height: 100%; */
  height: auto;
}

.news--box--content h3 {
  font-size: 1.3rem;
  font-weight: 500;
}

.news--box--content {
  margin-bottom: 3rem;
}

.news--box-date {
  margin-top: 1rem;
}

.news--box--link-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.news--box--link {
  font-weight: 600;
  color: var(--color-primary);
}

.event--box .front {
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: relative;
  height: 400px;
}

.event--box .front .event--box--content {
  height: 100%;
  width: 100%;
  background: rgba(104, 40, 32, 0.5);
  z-index: 12;
  text-align: center;
  color: white;
  padding: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: column;
  overflow: hidden;
}

.front .event--box-date {
  color: var(--color-secondary);
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-family: var(--font-header);
  font-size: 1.3rem;
  width: 100%;
}

.front .event--box--content h3 {
  font-family: var(--font-header);
  font-size: 1.6rem;
  line-height: 2.5rem;
  width: 100%;
}

.back .event--box--content {
  height: 100%;
  width: 100%;
  background: var(--color-secondary);
  z-index: 12;
  text-align: center;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: column;
  overflow: hidden;
}

.back .event--box-date {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: var(--font-header);
  font-size: 1.2rem;
  width: 100%;
}

.box--event--text {
  width: 100%;
}

.back .event--box--content h3 {
  font-family: var(--font-header);
  font-size: 2rem;
  line-height: 2.5rem;
  width: 100%;
}

.back .event--box--link-container {
  margin-top: 1rem;
  width: 100%;
}

.event--box-bottem {
  background: white;
  content: ' ';
  width: 50px;
  height: 50px;
  border-radius: 100px;
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
}

.event--box--arrow {
  height: 1rem;
  margin-top: 10px;
}

/* entire container, keeps perspective */
.flip-container {
  perspective: 1000px;
}

/* flip the pane when hovered */

.flip-container, .front, .back {
  height: 100%;
}

/*.flip-container, .front, .back {
  width: 320px;
  height: 480px;
}*/

/* flip speed goes here */
.flipper {
  transform-style: preserve-3d;
  position: relative;
}

/* hide back of pane during swap */
.front, .back {
  backface-visibility: hidden;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* front pane, placed above back */
.front {
  transition: 0.6s;
  transform-style: preserve-3d;
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}

.flip-container:hover .front {
  transform: rotateY(180deg);
}

/* back, initially hidden pane */
.back {
  transition: 0.6s;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
}

.flip-container:hover .back {
  transform: rotateY(360deg);
}

@media screen and (min-width: 35.5rem) {
  .news--box,
  .event--box {
    width: 48%;
  }
  section.news.news--content .news--box,
  section.news.news--content .event--box{
    width: calc(98% / 2);
    margin-right: 1%;
  }
  /*.news--box {
    height: 400px;
  }*/
}

@media screen and (min-width: 48rem) {
  .news-wrapper > h2,
  .event-wrapper > h2 {
    text-align: left;
  }

  .news--box,
  .event--box {
    width: 48%;
  }
  section.news.news--content .news--box,
  section.news.news--content .event--box{
    width: calc(97% / 3);
    margin-right: 1%;
  }
  /* .news--box {
     height: 450px;
   }*/
}

@media screen and (min-width: 64rem) {
  .news-wrapper,
  .event-wrapper {
    width: 48%;
  }
  section.news.news--content .news-wrapper,
  section.news.news--content .event-wrapper{
    width: 100%;
  }
  section.news.news--content .news--box,
  section.news.news--content .event--box{
    width: calc(96% / 4);
    margin-right: 1%;
  }
  /*.news--box {
    height: 400px;
  }*/
  .news--box--link-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}