.main-footer {
  background-size: auto;
  background: url(img/punkte-hintergrund-rot.png) repeat left top;
  color: white;
  padding-top: 1rem;
}

.main-footer > .container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footer-box {
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 100%;
  text-align: center;
}

.footer-box-mail {
  background: url(img/icon_mail.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.footer-box-tel {
  background: url(img/phone-call.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.footer-box--telNumber {
  font-family: var(--font-header);
  font-size: 1.6rem;
  color: white;
}

.footer-box--email {
  font-family: var(--font-header);
  font-size: 1.6rem;
  color: white;
}
.footer-box--telNumber:hover,
.footer-box--email:hover {
  color: var(--color-secondary);
}
.footer-box-newsletter--titel {
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
  padding-bottom: 0;
  text-align: center;
}

.footer-box-newsletter--hinweis {
  font-family: var(--font-header);
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.footer-box-newsletter input {
  height: 2.5rem;
  padding: 0.5rem;
  width: 100%;
}

.footer-box-newsletter--button {
  margin-top: 1rem;
  width: 100%;
  height: 2.5rem;
}

.sub-footer {
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.sub-footer ul {
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.sub-footer ul li {
  padding-right: 1rem;
}

.footer-map-overlay {
  width: 250px;
  height: auto;
  position: absolute;
  top: 15%;
  left: 15%;
  background: rgba(255, 255, 255, 0.4);
  padding: 1rem;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.39);
}

.footer-map-overlay-inner {
  background: white;
  padding: 1rem;
  text-align: center;
  font-weight: 700;
}

.circle-shadow {
  position: absolute;
  bottom: -32px;
  left: 0;
  width: 250px;
  height: 30px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#212121+0,000000+78&1+0,1+0,0+52 */
  background: -moz-radial-gradient(center, ellipse cover, rgba(33, 33, 33, 1) 0%, rgba(11, 11, 11, 0) 52%, rgba(0, 0, 0, 0) 78%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(33, 33, 33, 1) 0%, rgba(11, 11, 11, 0) 52%, rgba(0, 0, 0, 0) 78%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(33, 33, 33, 1) 0%, rgba(11, 11, 11, 0) 52%, rgba(0, 0, 0, 0) 78%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#212121', endColorstr='#00000000', GradientType=1); /* IE6-9 fallback on horizontal gradient */

}

@media screen and (min-width: 48rem) {
  .footer-box {
    width: 30%;
  }

  .main-footer {
    padding-top: 0;
  }

  .footer-box-newsletter--hinweis,
  .footer-box-newsletter--titel {
    text-align: left;
  }


}
@media screen and (min-width: 80rem) {
  .footer-box-newsletter input {
    width: 65%;
  }

  .footer-box-newsletter--button {
    width: 30%;
    margin-top: 0;
  }
}