.content {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.search article {
  margin-bottom: 1rem;
}

.search {
  margin-bottom: 2rem;
}

.content h1 {
  color: var(--color-primary);
  text-align: left;
}
.content h2 {
  color: var(--color-secondary);
  text-align: left;
}
.content h4,
.content h5,
.content h6 {

}
.content a {
  color: var(--color-primary);
}

.content a.btn-primary {
  color: white;
}

.content a:hover.btn-primary {
  color: white;
}

.content a:hover {
  color: color(var(--color-primary) a(60%));
}

.content ul {
  list-style: none;

}

.content > article {
  margin-bottom: calc(var(--gutter) * 2);
  margin-top: calc(var(--gutter) * 2);
}
.content .gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}
.content .gallery a {
  width: 48%;
  margin-right: 1%;
  display: block;
  position: relative;
}
.content .gallery a.content---image--one {
  width: 100%;
}

.layout-2,
.layout-3 {
  text-align: center;
}


.layout-2 .content-left {
  margin-right: 0;
  position: relative;
}

.layout-2 .content-right {
  padding: 0.5rem;
  padding-top: 0;
}

.layout-3 .content-right {
  border: solid 2px white;
  margin-left: 0;
  position: relative;
}

.layout-3 .content-right.noBorder {
  border: none;
  text-align: center;
  text-align: -webkit-center;
}

.layout-3 .content-left {
  padding: 0 0.5rem 0.5rem;
}

.layout-4 {
  text-align: left;
}
.content .gallery.layout-4-images {
  justify-content: flex-start;
}
.content .gallery.layout-4-images a {
  width: calc(96% / 4);
  margin-right: 1%;
}

.layout-4-images a img {
  border: solid 2px white;
}

@media screen and (min-width: 48rem) {
  .content ul {
    list-style: inherit;
    margin-left: 30px;
  }


  .layout-4-images a {
    width: 19%;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .layout-3 .content-right.noBorder {
    text-align: left;
    text-align: -webkit-left;
  }

  .layout-2 .content-left.noBorder {
    text-align: right;
    text-align: -webkit-right;
  }

  .layout-3 {
    text-align: left;
  }

  .layout-2 {
    text-align: left;
  }

  .layout-2 .content-left {
    margin-right: 0.5rem;
  }

  .layout-3 .content-right {
    margin-left: 0.5rem;
  }
}