/*
 * Filename: forms.css
 *
 * Default Styles for Forms.
 */

label,
.pure-form label {
  display: block;
}

textarea,
.pure-form textarea {
  display: block;
}

.pure-form fieldset {
  margin: 0;
  border: 0;
  padding: 0;
}

.btn {
  border-style: none;
  border-radius: 0;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background .7s, color .7s;
  outline: none;
}

.btn-primary {
  background: var(--color-primary);
  color: white;

}

.btn-primary:hover {
  background: var(--color-secondary);
}

.btn-secondary {
  background: var(--color-secondary);
  color: white;
}

.btn-secondary:hover {
  background: color(var(--color-secondary) a(70%));
  color: white;
}

.btn-simple {
  background: white;
  color: var(--color-secondary);
  border: solid 1px var(--color-secondary);

}

.btn-simple:hover {
  background: var(--color-primary);
  color: white;
  border: solid 1px var(--color-primary);
}

